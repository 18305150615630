<script>
/*eslint-disable*/
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import router from "@/router";
import { HTTP } from "@/main-source";
import Swal from "sweetalert2";
import { required, email, minLength } from "vuelidate/lib/validators";
import modal from "@/components/modal";

export default {
  name: "event-surveys",
  page: {
    meta: [{ name: "Company Details" }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      xhrRequest: true,
      data: null,
      eventSurveys: [],
      selectedSurvey: null,
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },

        {
          label: "Action",
          field: "action",
        },
      ],
      newInviteColumns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Phone",
          field: "phone",
        },
        {
          label: "Status",
          field: "status",
        },
      ],
      invitationsRows: [],
      newInviteRows: [],
      newInviteModal: false,
      acceptedInvitationsCount: 0,

      invitationsRows: [],
    };
  },
  validations: {
    subuser: {
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(5) },
    },
    newUsageRight: {
      count: { required },
    },
  },
  created() {
    this.getData();
    this.getEventSurveys();
  },
  methods: {
    getData() {
      this.xhrRequest = true;
      this.list = [];
      let uri = "firms";

      uri += "/" + this.$route.params.id + "?includePassive=true";
      HTTP.get(uri)
        .then((result) => {
          this.data = result.data.rows[0];

          this.xhrRequest = false;
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting your data",
            showConfirmButton: false,
            timer: 1500,
          });
          this.xhrRequest = false;
        });
    },
    getEventSurveys() {
      this.eventSurveys = [];
      let uri =
        "firms/" +
        this.$route.params.id +
        "/events/" +
        this.$route.params.eventId +
        "/surveys";
      HTTP.get(uri)
        .then((result) => {
          this.eventSurveys = result.data.rows;
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting your data",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    getAnswers() {
      this.invitationsRows = [];
      HTTP.get("surveys/" + this.selectedSurvey + "/answers").then((result) => {
        if (result.data) {
          result.data.forEach((answer) => {
            this.invitationsRows.push({
              id: answer.id,
              name: "*****",
              email: "*****",
              shortlink: answer.shortlink,
            });
          });
        }
      });
    },
    seeAnswers(shortlink) {
      router.push({ name: "survey-answer", params: { shortlink } });
    },
  },
  watch: {
    selectedSurvey() {
      this.getAnswers();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageTitle" />
    <div class="row" :class="{ waiting: xhrRequest }">
      <div class="spinner-border text-success" role="status" v-if="xhrRequest">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="d-flex w-100" v-if="!xhrRequest">
        <div class="col-lg-3">
          <div class="card">
            <div class="row">
              <div class="col-12 p-3 pr-4" v-if="$isAdmin()">
                <button
                  type="button"
                  class="btn btn-info floated-button btn-rounded"
                  @click="goEdit"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <img
                :src="
                  data.profileImg
                    ? $apiDomain + data.profileImg
                    : require('@/assets/images/no-image.jpg')
                "
                alt="Card image"
                class="card-img-top"
              />
              <h4 class="card-title mt-3">
                <h4 class="card-title text-center">{{ data.name }}</h4>
              </h4>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="form-group col-sm-6">
            <label>Select Survey</label>

            <div class="col-12">
              <div class="row">
                <select class="form-control" v-model="selectedSurvey">
                  <option value="null">Please select</option>
                  <option
                    v-for="(survey, index) in eventSurveys"
                    :value="survey.id"
                    :key="index"
                  >
                    {{ survey.name }}
                  </option>
                </select>
              </div>
            </div>

            <hr />
            <vue-good-table
              :columns="columns"
              :rows="invitationsRows"
              ref="invitations"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: false,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true,
                selectAllByGroup: true,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <button
                    class="btn btn-light"
                    v-if="props.row.status != 'Accepted'"
                    @click="seeAnswers(props.row.shortlink)"
                  >
                    <i class="ri-send-plane-fill"></i>
                    See Answers
                  </button>
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>